import { CreateEncumbranceFormType } from '@dltru/dfa-models'
import { Box, Col, InfoCardsDfa, PageHeader, Row } from '@dltru/dfa-ui'
import { IFooter } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { walletEncumbranceSlice } from '@store/wallet/encumbrance'

import { EmissionDecisionCardForOwnershipTransfer } from '@components/EmissionDecisionCard'

import { DFAEncumbranceCreateForm } from './components/DFAEncumbranceCreateForm'
import { Footer } from './components/Footer'

export const DFAEncumbranceCreate: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const { dfa: urlDfaParam } = useParams()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [footerStatus, setFooterStatus] = useState<IFooter['status']>('ready-to-new')

    useEffect(() => {
        if (urlDfaParam && dfaDetails.id !== Number(urlDfaParam)) {
            reduxDispatch(getDfaById({ dfaId: Number(urlDfaParam), withBalance: true }))
        }
        return () => {
            reduxDispatch(walletEncumbranceSlice.actions.clearData())
        }
    }, [urlDfaParam])

    if (!dfaDetails?.id) {
        return null
    }

    const onFormSubmit = (value: CreateEncumbranceFormType) => {
        reduxDispatch(walletEncumbranceSlice.actions.setCreateItem(value))
        setFooterStatus('precreate')
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Наложение обременения на ЦФА" onBack={() => navigate(-1)} />
            </Box>

            <Box padding={[0, 24, 24, 24]} className="tablebox tablebox--auto-height">
                <Row gutter={16}>
                    <Col span={24}>
                        <EmissionDecisionCardForOwnershipTransfer dfa={dfaDetails} />
                    </Col>
                    <InfoCardsDfa data={dfaDetails.emitter_info} />
                </Row>
            </Box>
            <DFAEncumbranceCreateForm onFormSubmit={onFormSubmit} />
            <Footer status={footerStatus} />
        </>
    )
}
