import { CONTENT_FOOTER_ID, ContentFooterBox, IFooter, Portal } from '@dltru/dfa-ui'
import { Button } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { profileSelector } from '@store/profiles/details/selectors'

import { ModalEncumbranceCreate } from './ModalEncumbranceCreate'
import { ModalEncumbranceRemove } from './ModalEncumbranceRemove'

export const Footer: FC<Pick<IFooter, 'status'>> = ({ status }) => {
    const [openCreateEncumbrance, setOpenCreateEncumbrance] = useState(false)
    const [openRemoveEncumbrance, setOpenRemoveEncumbrance] = useState(false)
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const isPartiallyBlocked = useSelector(profileSelector.selectIsUserBlocked)

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {status === 'precreate' && (
                            <Button
                                type="primary"
                                borderRadius={12}
                                onClick={() => setOpenCreateEncumbrance(true)}
                                disabled={isPartiallyBlocked}
                                tooltipOnDisable="Действие заблокировано. Обратитесь в службу поддержки."
                            >
                                Наложить обременение
                            </Button>
                        )}
                        {status === 'success' && (
                            <Button
                                type="primary"
                                borderRadius={12}
                                onClick={() => setOpenRemoveEncumbrance(true)}
                                disabled={isPartiallyBlocked}
                                tooltipOnDisable="Действие заблокировано. Обратитесь в службу поддержки."
                            >
                                Снять обременение
                            </Button>
                        )}
                    </ContentFooterBox>
                </Portal>
            )}
            <ModalEncumbranceCreate
                visible={openCreateEncumbrance}
                onCancel={() => setOpenCreateEncumbrance(false)}
            />
            <ModalEncumbranceRemove
                visible={openRemoveEncumbrance}
                onCancel={() => setOpenRemoveEncumbrance(false)}
            />
        </>
    )
}
