import {
    ClientType,
    ClientTypeTranslate,
    CreateEncumbranceFormType,
    ProfileType,
} from '@dltru/dfa-models'
import {
    Button,
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    Divider,
    Form,
    IntegerInput,
    WrappedInput,
    WrappedSelect,
    dfaValidators,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { InnInput } from '@components/Form'
import { UploadInputForm } from '@components/Form/components/UploadInputForm'

const clientTypeDescriptionOptions = Object.entries(ClientTypeTranslate).map(([value, label]) => ({
    value,
    label,
}))
const defaultClientType = ProfileType.LEGL

interface IComponentProps {
    onFormSubmit: (value: CreateEncumbranceFormType) => void
}

export const DFAEncumbranceCreateForm: FC<IComponentProps> = ({ onFormSubmit }) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const userFullName = useSelector(profileSelector.userFullName)
    const userId = useSelector(authSelector.selectUserUid)
    const [uneditable, setUneditable] = useState(false)

    const [currentClientType, setCurrentClientType] = useState<ClientType>(defaultClientType)

    const [form] = Form.useForm<CreateEncumbranceFormType>()

    const maxCount = dfaDetails.balance?.active_amount_dfa ?? 0

    const onChangeClientType = (value: ClientType) => {
        setCurrentClientType(value)
        form.resetFields(['depositor_inn'])
    }

    const onFinish = (value: CreateEncumbranceFormType) => {
        setUneditable(true)
        onFormSubmit({ ...value, asset_id: dfaDetails.id })
    }

    const pledgerRenderUneditable = () => <Link to={`/clients/${userId}`}>{userFullName}</Link>

    return (
        <Form form={form} size="large" onFinish={onFinish}>
            <Card uneditable={uneditable}>
                <CardHeader title="Информация об обременении" />
                <CardContent divider={!uneditable}>
                    <div className="formRow" style={{ marginTop: 16 }}>
                        <WrappedInput
                            className="formComponentItem"
                            label="Залогодатель"
                            name="depositor"
                            required
                            initialValue={userFullName}
                            size="large"
                            disabled
                            uneditable={uneditable}
                            renderUneditable={pledgerRenderUneditable}
                        />
                    </div>
                    <Divider margin={[8, 0, 32, 0]} />
                    <div className="formRow">
                        <InnInput
                            label="ИНН Залогодержателя"
                            name="pledgee_inn"
                            placeholder={`Состоит из ${
                                currentClientType === ProfileType.LEGL ? 10 : 12
                            } цифр`}
                            required
                            isUl={currentClientType === ProfileType.LEGL}
                            checkInnExistenceFor={currentClientType}
                            checkInnExistenceText="Вы не можете создать обременение по указанному ИНН"
                            uneditable={uneditable}
                        />
                    </div>
                    <div className="formRow">
                        <WrappedSelect
                            name="pledgee_type"
                            required
                            label="Тип пользователя Залогодержателя"
                            className="formComponentItem"
                            options={clientTypeDescriptionOptions}
                            onChange={onChangeClientType}
                            initialValue={defaultClientType}
                            uneditable={uneditable}
                        />
                    </div>
                    <Divider margin={[8, 0, 32, 0]} />
                    <div className="formRow" style={{ marginBottom: 16 }}>
                        <IntegerInput
                            className="formComponentItem"
                            label="Количество ЦФА, в отношении которых накладывается обременение"
                            name="amount_dfa"
                            placeholder={`В кошельке доступно максимум ${maxCount}`}
                            required
                            rules={[
                                {
                                    validator: dfaValidators.biggerAmount(maxCount),
                                },
                            ]}
                            uneditable={uneditable}
                        />
                    </div>
                    <div className="formRow">
                        <WrappedInput
                            className="formComponentItem--xl"
                            label="Основание для наложения обременения"
                            name="set_reason"
                            required
                            placeholder="Кратко опишите основание для наложения обременения"
                            multiline
                            uneditable={uneditable}
                        />
                    </div>
                    <div className="formRow" style={{ marginLeft: 240 }}>
                        <UploadInputForm
                            className="formComponentItem"
                            name="set_attachment_uuid"
                            required
                            uneditable={uneditable}
                            title="Загрузите фотографии документов-оснований. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                            maxSizeMb={5}
                            accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                        />
                    </div>
                </CardContent>
                {!uneditable && (
                    <CardFooter>
                        <div>
                            <Button
                                borderRadius={12}
                                type="primary"
                                size="middle"
                                htmlType="submit"
                            >
                                Далее
                            </Button>
                        </div>
                    </CardFooter>
                )}
            </Card>
        </Form>
    )
}
