// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".boldHeader_fhJyT {\n  margin-bottom: 24px;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.footerWrapper__BHGq {\n  margin: 24px 0;\n  display: flex;\n  gap: 8px;\n  justify-content: flex-end;\n}\n.grayBottomText__boiQ {\n  color: #868991;\n  text-align: center;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAEncumbrance/components/ModalEncumbranceRemove/ModalEncumbranceRemove.m.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADF;AAIA;EACE,cAAA;EACA,aAAA;EACA,QAAA;EACA,yBAAA;AAFF;AAKA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAHF","sourcesContent":["@import 'style/palette';\n\n.boldHeader {\n  margin-bottom: 24px;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: @gray-10\n}\n\n.footerWrapper {\n  margin: 24px 0;\n  display: flex;\n  gap: 8px;\n  justify-content: flex-end;\n}\n\n.grayBottomText {\n  color: @gray-7;\n  text-align: center;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boldHeader": "boldHeader_fhJyT",
	"footerWrapper": "footerWrapper__BHGq",
	"grayBottomText": "grayBottomText__boiQ"
};
export default ___CSS_LOADER_EXPORT___;
