import { CertificateType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    ConditionsModal,
    Divider,
    Form,
    MiniModal,
    ModalProps,
    QuestionCircle,
    Space,
    UploadFile,
    WrappedInput,
} from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { walletEncumbranceSlice } from '@store/wallet/encumbrance'
import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import styles from './ModalEncumbranceRemove.m.less'
import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

type FormDataType = {
    removal_attachment_uuid: UploadFile[]
    removal_reason: string
}
export const ModalEncumbranceRemove: FC<Pick<ModalProps, 'visible' | 'onCancel'>> = ({
    visible,
    onCancel,
}) => {
    const [form] = Form.useForm<FormDataType>()
    const reduxDispatch = useDispatch()
    const encumbrance = useSelector(encumbranceSelectors.selectItem)
    const [documents, setDocuments] = useState([])
    const [isFilled, setIsFilledField] = useState(false)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const [isConditionModalVisible, setIsConditionModalVisible] = useState(false)

    const openConditionModal = () => {
        setIsConditionModalVisible(true)
    }

    const closeModal = () => {
        onCancel?.()
        setIsFilledField(false)
        setDocuments([])
        form.setFieldsValue({ removal_reason: '', removal_attachment_uuid: undefined })
    }

    const removeEncumbrance = (cert: CertificateType) => {
        if (encumbrance) {
            const values = form.getFieldsValue()
            reduxDispatch(
                walletEncumbranceSlice.actions.removeEncumbrancePrefly({
                    ...values,
                    removal_attachment_uuid: values.removal_attachment_uuid?.map(({ uid }) => uid),
                    encumbrance_uuid: encumbrance.uuid,
                    skid: cert.skid,
                }),
            )
        }
    }

    const singRemoveEncumbrance = (cert: CertificateType) => {
        if (encumbrance) {
            reduxDispatch(
                walletEncumbranceSlice.actions.removeEncumbrance(
                    cert.skid,
                ),
            )
        }
        closeModal()
    }

    const isBtnDisabled = !documents?.length || !isFilled

    const onValuesChange = (changedValues: FormDataType) => {
        setIsFilledField(Boolean(changedValues.removal_reason))
    }

    const text = (
        <>
            <p>
                Нажимая кнопку «Да, подтвердить» Пользователь в соответствии с пунктом 14.8 Правил
                направляет указание на внесение записи о снятии обременения ЦФА в отношении ЦФА,
                указанных в форме «Обременение ЦФА», в количестве, указанном в графе «Количество
                ЦФА, в отношении которых накладывается обременение» такой формы.
            </p>
            <p>
                Основание для внесения записи о снятии обременения ЦФА содержится в документе,
                приложенном Пользователем к форме «Снять обременение на ЦФА». Во избежание сомнений
                Оператор не проверяет правомерность такого основания.
            </p>
            <p>
                Все термины, встречающиеся в тексте Заявки на покупку с заглавной буквы, применяются
                в значении, предусмотренном правилами информационной системы Общества с ограниченной
                ответственностью «Системы распределенного реестра».
            </p>
        </>
    )

    return (
        <MiniModal
            width={600}
            visible={visible}
            onCancel={closeModal}
            destroyOnClose
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Снять обременение на ЦФА
                </Space>
            }
        >
            <Box margin={[0, 40]}>
                <div>
                    Вы действительно хотите снять обременение с ЦФА, наложенного в пользу указанного
                    лица?
                </div>
                <Divider />
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={() => setIsVisibleCertificateModal(true)}
                    onValuesChange={onValuesChange}
                >
                    <h4 className={styles.boldHeader}>Основание для снятия обременения</h4>
                    <UploadDocumentContainerWrapper
                        name="removal_attachment_uuid"
                        onChange={setDocuments}
                        title="Загрузите фотографии документов-оснований.  Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG. Общий вес не более 25 МБ"
                        required
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Кратко опишите основание для снятия обременения"
                            name="removal_reason"
                            multiline
                        />
                    </UploadDocumentContainerWrapper>
                </Form>
            </Box>
            <div className={styles.footerWrapper}>
                <Button borderRadius={12} onClick={closeModal}>
                    Отмена
                </Button>
                <Button
                    type="primary"
                    borderRadius={12}
                    onClick={() => form.submit()}
                    disabled={isBtnDisabled}
                >
                    Да, снять обременение
                </Button>
            </div>
            <div className={styles.grayBottomText}>
                Снимая обременение с ЦФА, вы соглашаетесь с{' '}
                <Button type="linkText" onClick={openConditionModal}>
                    условиями
                </Button>
                .
            </div>

            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={removeEncumbrance}
                onSign={singRemoveEncumbrance}
            />

            <ConditionsModal
                isModalVisible={isConditionModalVisible}
                setIsModalVisible={setIsConditionModalVisible}
                title="Условия"
                text={text}
            />
        </MiniModal>
    )
}
