import { CertificateType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    ConditionsModal,
    MiniModal,
    ModalProps,
    QuestionCircle,
    Space,
} from '@dltru/dfa-ui'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { walletEncumbranceSlice } from '@store/wallet/encumbrance'
import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

export const ModalEncumbranceCreate: FC<Pick<ModalProps, 'visible' | 'onCancel'>> = ({
    visible,
    onCancel,
}) => {
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const [isConditionModalVisible, setIsConditionModalVisible] = useState(false)
    const navigate = useNavigate()
    const data = useSelector(encumbranceSelectors.selectCreateData)
    const reduxDispatch = useDispatch()

    const openConditionModal = () => {
        setIsConditionModalVisible(true)
    }

    const createEncumbrance = (cert: CertificateType) => {
        reduxDispatch(walletEncumbranceSlice.actions.createEncumbrancePrefly(cert.skid))
    }

    const signCreateEncumbrance = (cert: CertificateType) => {
        reduxDispatch(walletEncumbranceSlice.actions.createEncumbrance(cert.skid))
    }

    useEffect(() => {
        if (data?.uuid) {
            onCancel?.()
            navigate(-1)
        }
    }, [data?.uuid])

    const text = (
        <>
            <p>
                Нажимая кнопку «Да, подтвердить» Пользователь в соответствии с пунктом 14.7 Правил
                направляет указание на внесение записи об установлении обременения ЦФА в отношении
                ЦФА, указанных в форме «Наложение обременения на ЦФА», в количестве, указанном в
                графе «Количество ЦФА, в отношении которых накладывается обременение» такой формы.
            </p>
            <p>
                Все термины, встречающиеся в тексте Заявки на покупку с заглавной буквы, применяются
                в значении, предусмотренном правилами информационной системы Общества с ограниченной
                ответственностью «Системы распределенного реестра».
            </p>
        </>
    )

    return (
        <MiniModal
            width={423}
            visible={visible}
            onCancel={onCancel}
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Наложить обременение на ЦФА
                </Space>
            }
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button
                    type="primary"
                    borderRadius={12}
                    onClick={() => setIsVisibleCertificateModal(true)}
                >
                    Да, подтвердить
                </Button>,
            ]}
        >
            <Box margin={[0, 0, 0, 40]}>
                <Space direction="vertical" size={18}>
                    <div>Вы действительно хотите наложить обременение на ЦФА?</div>
                    <div>
                        Накладывая обременение на ЦФА, вы соглашаетесь с{' '}
                        <Button type="linkText" onClick={openConditionModal}>
                            условиями
                        </Button>
                        .
                    </div>
                </Space>
            </Box>

            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={createEncumbrance}
                onSign={signCreateEncumbrance}
            />
            <ConditionsModal
                isModalVisible={isConditionModalVisible}
                setIsModalVisible={setIsConditionModalVisible}
                title="Условия"
                text={text}
            />
        </MiniModal>
    )
}
